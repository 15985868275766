import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Outdoor Stride Hub
			</title>
			<meta name={"description"} content={"Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари."} />
			<meta property={"og:title"} content={"Outdoor Stride Hub"} />
			<meta property={"og:description"} content={"Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});